<template>
  <div class="phone-error">
    <div class="phone-error__container">
      <p class="phone-error__text">
        Указан неверный номер
      </p>
      <button @click="$emit('close')" class="btn-red phone-error__button">
        Попробовать еще раз
      </button>
    </div>
  </div>
</template>

<script>
import Mixin from '../common/mixin';

export default {
  mixins: [Mixin]
}

</script>

<style lang="scss" scoped>
.phone-error {
	position: fixed;
	z-index: 305;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0,0,0, 0.5);
	&__container {
		max-width: 500px;
		width: 100%;
    padding: 15px;
    background: #fff;
	}
  &__text {
    margin-bottom: 15px;
    text-align: center;
  }
  &__button {
    width: 100%;
    max-width: unset;
  }
}
</style>