<template>
  <footer class="block-footer">
    <div class="block-footer__container">
      <div
        class="block-footer__disclamer-switch"
        @click="show_conditions = !show_conditions"
      >
        Полные условия акции
      </div>
      <div
        class="block-footer__disclamer block-footer__disclamer--open-anim"
        v-show="show_conditions"
      >
        *Максимальная выгода предоставляется на модели: Tiggo 4 PRO составляет 270 000 рублей, на 7 PRO выгода достигает 325 000 рублей, на обновленный Tiggo 7 PRO MAX - 305 000 рублей, на Tiggo 8 PRO - 565 000 рублей и на полноприводный Tiggo 8 PRO MAX - 590 000 рублей, на гибридный Tiggo 8 PRO e+ - 880 000 рублей, на бизнес-седан Arizzo 8 - 465 000 рублей.
        <br><br>Количество автомобилей в рамках предложения ограничено. Не является публичной офертой. Выгода указана с учетом всех действующих программ, подробнее у продавцов-консультантов. Чери Волхонский сохраняет за собой право вносить изменения в указанные предложения в любое время.
        <br><br>Предложение ограничено, подробности на chery.ru.
        <br><br>Финансовые услуги оказывает ПАО РОСБАНК, генеральная лицензия №2272 от 28.01.15. Первоначальный взнос от 20%. Срок кредита 36 мес. с пролонгацией до 84 мес. Обязательно оформление опции банка «Назначь свою ставку» стоимостью от 9,5% до 15,5% от суммы кредита. 
        <br>Опция оплачивается единовременно при заключении кредитного договора путем включения в сумму кредита. Отказ от опции не предусмотрен. Не оферта.
      </div>
    </div>

    <div class="yur-info">
      <p>© 2023 ООО "ИАТ ГОЛД"</p>
      <p>
        Волхонское шоссе, 3с2, Виллозское городское поселение, Ломоносовский район, Ленинградская область
      </p>
      <p>ИНН: 4725009047</p>
      <p>ОГРН: 1204700014034</p>
      <p>2023 ООО “ИАТ ГОЛД”, входит в группу компаний Акционерного Общества “Интер Авто Тим” и является официальным дилером Chery в России</p>
    </div>

    <a 
      rel="noopenet" 
      href="https://cherydealer.ru/"
      target="_blank"
      class="politic-text"
    >
      Условия обработки персональных данных
    </a>
    <div class="timer" ref="timer"></div>
  </footer>
</template>

<script>
import Mixin from '../common/mixin';

export default {
  mixins: [Mixin],
  data() {
    return {
      show_conditions: false,
      CONSTANTS: this.CONSTANTS,
    };
  },
  computed: {
    today() {
      return new Date();
    },
    lastDay() {
      return new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);
    },
    disc_start_date() {
      return `01.${
        +this.today.getMonth() + 1 < 10 ? "0" : ""
      }${this.today.getMonth() + 1}.${this.today.getFullYear()}`;
    },
    disc_end_date() {
      return `${this.lastDay.getDate()}.${
        +this.today.getMonth() + 1 < 10 ? "0" : ""
      }${this.today.getMonth() + 1}.${this.today.getFullYear()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.politic-text {
  display: block;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  margin: 4px 0;
  cursor: pointer;
}

@keyframes conditions-show {
  from {
    opacity: 0;
    height: 0;
  }

  90% {
    opacity: 1;
    height: 200px;
  }

  to {
    height: auto;
  }
}

@keyframes conditions-close {
  from {
    height: 200px;
    opacity: 1;
  }

  to {
    height: 0;
    opacity: 0;
  }
}

.yur-info {
  font-size: 14px;
  text-align: center;

  & p {
    margin: 0;
  }
}

.block-footer {
  padding-bottom: 10px;
  &__container {
    margin: 0px auto;
    max-width: 1340px;
    position: relative;
    padding: 0px 15px;
    text-align: center;
  }

  &__disclamer {
    display: block;

    font-size: 10px;
    text-align: left;
    color: black;
    padding-bottom: 20px;

    &--open-anim {
      animation-name: conditions-show;
      animation-duration: 0.5s;
    }

    &--close-anim {
      animation-name: conditions-close;
      animation-duration: 0.5s;
    }
  }

  &__disclamer-switch {
    color: black;
    text-decoration: underline;
    font-size: 12px;
    line-height: 100%;
    display: inline-block;
    text-decoration: underline;
    padding-top: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
</style>
