<template>
  <div :class="[device_platform]" class="wheel-container">
    <div class="blacking" @click="$emit('close')"></div>
    <div class="wheel">
      <div class="closer" @click="$emit('close')">×</div>
      <div class="wheel__content">
        <div class="wheel__col">
          <div class="wheel__title">КОЛЕСО ФОРТУНЫ CHERY</div>
          <div class="wheel__img-wrapper">
            <img src="../img/wheel-fortune.png" 
              alt="wheel" 
              class="wheel__img" 
              :class="{'animated': isAnimated}">

            <div class="wheel__ticker"></div>
          </div>
        </div>
        <div class="wheel__col col-2">
          <div v-if="showGift" class="wheel__gift">
            <div class="wheel__gift-title"> Вы выиграли <br><span class="red">СУПЕР-ВЫГОДУ</span>!</div>

            <div class="wheel__gift-text">
              Чтобы получить персональное предложение оставьте номер ниже - мы перезвоним в течение 5 минут!
            </div>
          
            <callback-input
              v-on="$listeners"
              :data="{ type: 'sale', form: 'contacts' }"
              form_class="full-get-form"
              :focusOnMount="true"
              >Отправить</callback-input
            >
          </div>

          <div v-else class="wheel__info">
            <div class="wheel__info-title">Дарим подарки <br>в <span class="black">Черную Пятницу</span>!</div>
            <div class="wheel__info-text">
              Испытайте удачу - выиграйте уникальные призы и невероятные выгоды на CHERY!
            </div>

            <div class="wheel__info-footer">Только в ИАТ Волхонский!</div>

            <button @click="spinWheel" class="btn btn-red wheel__btn">ИСПЫТАТЬ УДАЧУ!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mask from "../common/mask";
import Keyup from "../common/keyup";
import Mixin from "../common/mixin";
import CallbackInput from "./callback-form/callback-input";

export default {
  name: "wheel-of-fortune",
  components: { CallbackInput },
  mixins: [Mixin],
  directives: { Mask, Keyup },
  data() {
    return {
      isAnimated: false,
      showGift: false,
    }
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
    spinWheel() {
      this.isAnimated = true;
      setTimeout(() => {
        this.showGift = true;
      }, 3200)
    }
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";
.line-through {
  text-decoration: line-through;
  text-align: center;
}

.blacking {
  position: fixed;
  top: -100vh;
  left: -100vw;
  bottom: -100vw;
  right: -100vh;
  background: #000;
  opacity: 0.5;
  z-index: 20;
}

.wheel {
  position: fixed;
  top: 18vh;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 21;
  padding: 50px;
  width: 700px;

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  &__col {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
  }

  &__img {
    margin: 20px 0;
    user-select: none;

    &-wrapper {
      position: relative;
    }

    &.animated {
      transform: rotate(120deg);
      animation: 3s linear rotate;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(840deg);
    }
  }

  &__ticker {
    position: absolute;
    top: 50%;
    right: -14px;
    z-index: 1;
    transform: rotate(180deg);

    background-image: linear-gradient(to bottom, #000000 50%,  #1c1f14 50%);
    clip-path: polygon(20% 0, 100% 50%, 20% 100%, 0% 50%);
    width: 30px;
    height: 18px;
  }

  &__info, &__gift {
    text-align: center;

    &-title {
      margin-bottom: 15px;
      font-weight: 600;

      .red {
        font-weight: 700;
        color: red;
        font-size: 24px;
      }
    }

    &-text {
      margin-bottom: 20px;
    }

    &-footer {
      margin-bottom: 20px;
    }
  }

  &__gift {
    .full-get-form {
      padding-top: 0;
    }
  }
}

.closer {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 770px) {
  .wheel {
    top: 10vh;
    width: calcFontSize(300, 700, 320, 770);
    padding: 20px;
  }
}

@media (max-width: 500px) {
  .wheel {
    &__content {
      flex-direction: column;
    }

    &__col {
      width: 100%;
    }

    &__title {
      font-size: 15px;
      text-align: center;
    }

    &__img {
      margin: 0;

      &-wrapper {
        margin: 5px auto;
        width: 185px;
      }
    }

    &__info, &__gift {
      font-size: 14px;

      &-title {
        margin-bottom: 4px;

        .red {
          font-size: 16px;
        }
      }

      &-text {
        margin-bottom: 7px;
        font-size: 12px;
      }

      &-footer {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }

    &__btn {
      margin-top: 13px;
    }
    .full-get-form .input-block input {
      height: 40px;
    }
  }
}
</style>
