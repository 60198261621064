import CF from './common-functions';
import CarsInfo from './cars-info';

const prices = CarsInfo.CARS.filter(el => el.price).map(el => el.price);
const advantages = CarsInfo.CARS.filter(el => el.advantage).map(el => el.advantage);
const payments = CarsInfo.CARS.filter(el => el.payment).map(el => el.payment);
const MAX_ADVANTAGE = Math.max(...advantages);
const MIN_PRICE = Math.min(...prices);
const MIN_PAYMENT = Math.min(...payments);

let date = CF.getNextDate();

const PHONE = '+7 (812) 336-80-00'.replace(/\s/g, '\u00A0').replace(/-/g, '\u2011');

const PHONE_RAW = PHONE.replace(/\D+/g, "");

export default {
    phone: PHONE,
    phone_raw: PHONE_RAW,
    end_date: date,
    max_advantage: MAX_ADVANTAGE,
    min_price: MIN_PRICE,
    min_payment: MIN_PAYMENT,
    offices: [
        {
            name: 'Рольф',
            address: 'Ленинградская область, Ломоносовский район, Виллозское городское поселение, Волхонское шоссе, 3, стр. 2',
            postal_code: 141410,
            phone: PHONE,
            phone_raw: PHONE_RAW,
            worktime: {
                from: '09:00',
                to: '21:00'
            },
            INN: "5047059383",
            KPP: "772845002",
            OGRN: "1045009553577",
            coords: {
                // x: 30.244344,
                // y: 59.986484
                x: 30.166800,
                y: 59.797784,
            }
        }
    ],
    watchers: 23,
    brand: 'Chery',
    center_coords: {
        // x: 30.244344,
        // y: 59.986484
        x: 30.166800484143064,
        y: 59.797784677668226,
    },
    need_agreement: true,
    cKeeper: undefined,
    need_manager_phone: true,
    email_coupon: true
}