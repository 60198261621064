const BALANCE = {
  tiggo_8_pro: 29,
  tiggo_7_pro: 25,
  tiggo_4_pro: 23,
  tiggo_8: 22,
  tiggo_8_pro_max: 26,
  tiggo_7_pro_max: 20,
  tiggo_8_pro_e_plus: 10,
  arrizo: 6,
  tiggo_8_pro_max_new: 4,
};

const REAL_IMGS = {
  tiggo_8_pro: 4,
  tiggo_7_pro: 4,
  tiggo_4_pro: 7,
  tiggo_8: 4,
  tiggo_8_pro_max: 7,
  tiggo_7_pro_max: 13,
  tiggo_8_pro_e_plus: 4,
  arrizo: 12,
  tiggo_8_pro_max_new: 7,
};

export default {
  CARS: [
    {
      id: "tiggo_4_pro",
      name: "Tiggo 4 Pro",
      balance: BALANCE.tiggo_4_pro,
      price: 1879900,
      benefit: "370 000",
      gallery_imgs: REAL_IMGS.tiggo_4_pro,
      advantages: [
        {
          id: 'gift',
          icon: 'tires',
          text: 'Уникальные подарки от дилера'
        },
        {
          id: 'installment',
          icon: 'installment',
          text: 'Рассрочка 0%'
        },
        {
          id: 'credit',
          icon: 'money',
          text: 'Выгодный кредит'
        },
        {
          id: 'warranty',
          icon: 'warranty',
          text: 'Гарантия 7 лет'
        }
      ],
      colors: [
        {
          name: "black",
          title: "Глубокий черный",
          hex: "#000",
        },
        {
          name: "white",
          title: "Искрящийся белый",
          hex: "#e7e7e7",
        },
        {
          name: "blue",
          title: "Небесный голубой",
          hex: "#2a72b1",
        },
        {
          name: "silver",
          title: "Серебристый серый",
          hex: "#c5c5c5",
        },
        {
          name: "red",
          title: "Огненный красный",
          hex: "#af1a1f",
        },
        {
          name: "grey",
          title: "Стальной серый",
          hex: "#666666",
        },
      ],
    },
    {
      id: "tiggo_7_pro_max",
      name: "Tiggo 7 Pro Max",
      new: false,
      balance: BALANCE.tiggo_7_pro_max,
      price: 2574900,
      benefit: "355 000",
      gallery_imgs: REAL_IMGS.tiggo_7_pro_max,
      advantages: [
        {
          id: 'gift',
          icon: 'tires',
          text: 'Уникальные подарки от дилера'
        },
        {
          id: 'installment',
          icon: 'installment',
          text: 'Рассрочка 0%'
        },
        {
          id: 'credit',
          icon: 'money',
          text: 'Выгодный кредит'
        },
        {
          id: 'warranty',
          icon: 'warranty',
          text: 'Гарантия 7 лет'
        }
      ],
      colors: [
        {
          name: "grey",
          title: "Стальной серый",
          hex: "#d7d8dc",
        },
        {
          name: "red",
          title: "Огненно-красный",
          hex: "#d82d2e",
        },
        {
          name: "black",
          title: "Глубокий черный",
          hex: "#000",
        },
        {
          name: "silver",
          title: "Серебристый",
          hex: "#a5a9af",
        },
        {
          name: "white",
          title: "Искрящийся белый",
          hex: "#f0f0f0",
        },
        {
          name: "blue",
          title: "Глубокий синий",
          hex: "#4374b1",
        },
      ],
    },
    {
      id: "arrizo",
      name: "Arrizo 8",
      new: false,
      balance: BALANCE.arrizo,
      price: 2849900,
      benefit: "515 000",
      gallery_imgs: REAL_IMGS.arrizo,
      isClear: false,
      advantages: [
        {
          id: 'gift',
          icon: 'tires',
          text: 'Уникальные подарки от дилера'
        },
        {
          id: 'installment',
          icon: 'installment',
          text: 'Рассрочка 0%'
        },
        {
          id: 'credit',
          icon: 'money',
          text: 'Выгодный кредит'
        },
        {
          id: 'warranty',
          icon: 'warranty',
          text: 'Гарантия 7 лет'
        }
      ],
      colors: [
        {
          name: "blue",
          title: "Небесный синий",
          hex: "#2A72B1",
        },
        {
          name: "black",       
          title: "Глубокий черный",
          hex: "#000000",
        },
        {
          name: "green",
          title: "Глубокий зеленый",
          hex: "#2F8BA7",
        },
        {
          name: "grey",
          title: "Серебристый серый",
          hex: "#999999",
        },
        {
          name: "white",
          title: "Искрящийся белый",
          hex: "#F0F0F0",
        },
        {
          name: "red",
          title: "Огненно-красный",
          hex: "#d82d2e",
        },
      ],
    },
    {
      id: "tiggo_8",
      name: "Tiggo 8",
      balance: BALANCE.tiggo_8,
      price: 2719900,
      benefit: "390 000",
      gallery_imgs: REAL_IMGS.tiggo_8,
      advantages: [
        {
          id: 'gift',
          icon: 'tires',
          text: 'Уникальные подарки от дилера'
        },
        {
          id: 'installment',
          icon: 'installment',
          text: 'Рассрочка 0%'
        },
        {
          id: 'credit',
          icon: 'money',
          text: 'Выгодный кредит'
        },
        {
          id: 'warranty',
          icon: 'warranty',
          text: 'Гарантия 7 лет'
        }
      ],
      colors: [
        {
          name: "blue",
          title: "Лазурный",
          hex: "#4585ba",
        },
        {
          name: "black",
          title: "Глубокий черный",
          hex: "#000",
        },
        {
          name: "grey",
          title: "Серебристый",
          hex: "#A5A5A5",
        },
        {
          name: "white",
          title: "Искрящийся белый",
          hex: "#f0f0f0",
        },
        {
          name: "red",
          title: "Огненно-красный",
          hex: "#af1a1f",
        },
      ],
    },
    {
      id: "tiggo_8_pro",
      name: "Tiggo 8 Pro",
      balance: BALANCE.tiggo_8_pro,
      price: 2974900,
      benefit: "615 000",
      gallery_imgs: REAL_IMGS.tiggo_8_pro,
      advantages: [
        {
          id: 'gift',
          icon: 'tires',
          text: 'Уникальные подарки от дилера'
        },
        {
          id: 'installment',
          icon: 'installment',
          text: 'Рассрочка 0%'
        },
        {
          id: 'credit',
          icon: 'money',
          text: 'Выгодный кредит'
        },
        {
          id: 'warranty',
          icon: 'warranty',
          text: 'Гарантия 7 лет'
        }
      ],
      colors: [
        {
          name: "red",
          title: "Огненно-красный",
          hex: "#af1a1f",
        },
        {
          name: "black",
          title: "Глубокий черный",
          hex: "#000",
        },
        {
          name: "blue",
          title: "Небесный синий",
          hex: "#2a72b1",
        },
        {
          name: "purple",
          title: "Королевский пурпур",
          hex: "#5e4d64",
        },
        {
          name: "white",
          title: "Искрящийся белый",
          hex: "#f0f0f0",
        },
        {
          name: "grey",
          title: "Стальной серый",
          hex: "#A5A5A5",
        },
      ],
    },
    {
      id: "tiggo_8_pro_max",
      name: "Tiggo 8 Pro Max",
      new: false,
      balance: BALANCE.tiggo_8_pro_max,
      price: 3510900,
      benefit: "640 000",
      gallery_imgs: REAL_IMGS.tiggo_8_pro_max,
      advantages: [
        {
          id: 'gift',
          icon: 'tires',
          text: 'Уникальные подарки от дилера'
        },
        {
          id: 'installment',
          icon: 'installment',
          text: 'Рассрочка 0%'
        },
        {
          id: 'credit',
          icon: 'money',
          text: 'Выгодный кредит'
        },
        {
          id: 'warranty',
          icon: 'warranty',
          text: 'Гарантия 7 лет'
        }
      ],
      colors: [
        {
          name: "purple",
          title: "Королевский пурпур",
          hex: "#5e4d64",
        },
        {
          name: "black",
          title: "Глубокий черный",
          hex: "#000",
        },
        {
          name: "blue",
          title: "Небесный синий",
          hex: "#2a72b1",
        },
        {
          name: "white",
          title: "Искрящийся белый",
          hex: "#f0f0f0",
        },
        {
          name: "red",
          title: "Огненно-красный",
          hex: "#af1a1f",
        },
        {
          name: "silver",
          title: "Стальной серый",
          hex: "#b8b8b8",
        },
      ],
    },
    {
      id: "tiggo_8_pro_max_new",
      name: "New Tiggo 8 Pro Max",
      new: false,
      balance: BALANCE.tiggo_8_pro_max_new,
      price: 3650900,
      benefit: "640 000",
      gallery_imgs: REAL_IMGS.tiggo_8_pro_max_new,
      advantages: [
        {
          id: 'gift',
          icon: 'tires',
          text: 'Уникальные подарки от дилера'
        },
        {
          id: 'installment',
          icon: 'installment',
          text: 'Рассрочка 0%'
        },
        {
          id: 'credit',
          icon: 'money',
          text: 'Выгодный кредит'
        },
        {
          id: 'warranty',
          icon: 'warranty',
          text: 'Гарантия 7 лет'
        }
      ],
      colors: [
        {
          name: "purple",
          title: "Королевский пурпур",
          hex: "#5e4d64",
        },
        {
          name: "grey",
          title: "Стальной серый",
          hex: "#9D9D9D",
        },
        {
          name: "black",
          title: "Глубокий черный",
          hex: "#000",
        },
        {
          name: "white",
          title: "Искрящийся белый",
          hex: "#EFEFEF",
        },
        {
          name: "blue",
          title: "Небесный синий",
          hex: "#2A72B1",
        },
        {
          name: "metallic",
          title: "Стальной серый",
          hex: "#CFCFCF",
        },
        {
          name: "green",
          title: "Изумрудный зеленый",
          hex: "#2F8BA7",
        },
        {
          name: "silver",
          title: "Серебристый серый",
          hex: "#c5c5c5",
        },
      ],
    },
    {
      id: "tiggo_8_pro_e_plus",
      name: "Tiggo 8 Pro e+",
      new: false,
      balance: BALANCE.tiggo_8_pro_e_plus,
      price: 3829900,
      benefit: "1 000 000",
      gallery_imgs: REAL_IMGS.tiggo_8_pro_e_plus,
      isClear: false,
      advantages: [
        {
          id: 'gift',
          icon: 'tires',
          text: 'Уникальные подарки от дилера'
        },
        {
          id: 'installment',
          icon: 'installment',
          text: 'Рассрочка 0%'
        },
        {
          id: 'credit',
          icon: 'money',
          text: 'Выгодный кредит'
        },
        {
          id: 'warranty',
          icon: 'warranty',
          text: 'Гарантия 7 лет'
        }
      ],
      colors: [
        {
          name: "white",
          title: "Искрящийся белый",
          hex: "#EFEFEF",
        },
        {
          name: "black",
          title: "Глубокий черный",
          hex: "#000",
        },
        {
          name: "blue",
          title: "Небесный синий",
          hex: "#2A72B1",
        },
        {
          name: "grey",
          title: "Стальной серый",
          hex: "#9D9D9D",
        },
      ],
    },
    // {
    //   id: "tiggo_7_pro",
    //   name: "Tiggo 7 Pro",
    //   balance: BALANCE.tiggo_7_pro,
    //   benefit: BENEFITS.tiggo_7_pro,
    //   gallery_imgs: REAL_IMGS.tiggo_7_pro,
    //   profits: {
    //     dop: 'Зимняя резина и КАСКО в подарок',
    //   },
    //   colors: [
    //     {
    //       name: 'black',
    //       hex: '#000'
    //     },
    //     {
    //       name: 'blue',
    //       hex: '#2a72b1'
    //     },
    //     {
    //       name: 'cyan',
    //       hex: '#2f8ba7'
    //     },
    //     {
    //       name: 'dark-red',
    //       hex: '#df2924'
    //     },
    //     {
    //       name: 'grey',
    //       hex: '#373846'
    //     },
    //     {
    //       name: 'red',
    //       hex: '#af1a1f'
    //     },
    //     {
    //       name: 'white',
    //       hex: '#f0f0f0'
    //     },
    //   ]
    // },
  ].map((car) => {
    car.current_color = car.colors[0];
    return car;
  }),
};
