<template>
    <div class="agreement-full">
        <div class="blacking"
             @click="$root.$emit('close-agreement');"></div>
        <div class="agreement-container">
            <div class="close"
                 @click="$root.$emit('close-agreement');">X
            </div>
            <div class="agreement-text">
                <div class="agreement-header">
                    Политика АО «РОЛЬФ» в области обработки и обеспечения безопасности персональных данных
                </div>
                <div class="agreement-part">
                    <br>1. ОБЩИЕ ПОЛОЖЕНИЯ
                    <br><br>1.1. Настоящая Политика содержит описание принципов и подходов АО «РОЛЬФ» (далее – Компания) в отношении обработки и защиты персональных данных, обязанности и ответственность Компании при осуществлении такой обработки.

                    <br><br>1.2. Компания обеспечивает соблюдение прав и свобод граждан при обработке персональных данных, в том числе обеспечивает защиту прав на неприкосновенность частной жизни, личной и семейной тайн.

                    <br><br>1.3. При обработке персональных данных в Компании строго соблюдаются следующие принципы, предусмотренные Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных»:

                    <br>законность целей и способов обработки персональных данных и добросовестности;
                    <br>соответствие целей обработки персональных данных целям, заранее определенным и заявленным при сборе персональных данных, а также полномочиям Компании;
                    <br>соответствие объема и характера обрабатываемых персональных данных, способов обработки персональных данных целям обработки персональных данных;
                    <br>обеспечение достоверности персональных данных, их актуальности и достаточности для целей обработки, недопустимости обработки избыточных по отношению к целям сбора персональных данных;
                    <br>реализация организационных и технических мер по обеспечению безопасности персональных данных;
                    <br>повышение уровня знаний работников Компании в сфере обеспечения безопасности персональных данных при их обработке;
                    <br>стремления к постоянному совершенствованию системы защиты персональных данных.
                    <br><br>2. СУБЪЕКТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ И ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    <br><br>2.1. В Компании осуществляется обработка персональных данных следующих категорий субъектов:

                    <br>кандидаты на вакантные должности;
                    <br>работники Компании, в том числе бывшие;
                    <br>родственники работников Компании;
                    <br>работники группы компаний «РОЛЬФ»;
                    <br>клиенты, представители клиентов;
                    <br>контрагенты по гражданско-правовым договорам (представители юридических лиц, индивидуальные предприниматели, физические лица);
                    пользователи сайта.
                    <br><br>2.2. Основными целями обработки персональных данных в Компании являются:

                    <br>рассмотрение резюме и подбор кандидатов на вакантную должность для дальнейшего трудоустройства в Компанию;
                    <br>реализация прав и исполнение обязательств, возникших в связи с трудовыми отношениями с работниками;
                    <br>ведение корпоративных информационных справочников;
                    <br>предоставление социальных льгот работникам Компании и близким родственникам работников;
                    <br>оформление работникам Компании полисов добровольного медицинского страхования;
                    <br>оформление работникам Компании банковских карт в рамках «зарплатного проекта»;
                    <br>заключение гражданско-правовых договоров с юридическими лицами, индивидуальными предпринимателями, физическими лицами, а также выполнение обязательств, связанных с договорными правоотношениями, которые регулируются законом или договором;
                    <br>заключение, сопровождение, изменение договоров купли-продажи, ремонта, обслуживания автомобилей;
                    <br>предоставление услуг по прокату, ремонту и обслуживанию автомобилей;
                    <br>оказание содействия клиентам в заключении кредитных договоров и договоров страхования;
                    <br>обработка обращений клиентов;
                    <br>проведение маркетинговых мероприятий и поддержка клиентских программ;
                    <br>обеспечение взаимодействия со СМИ;
                    <br>анализ статистики посещаемости, пользовательской активности и оптимизации сайта Компании;
                    <br>осуществление пропускного режима на территории Компании.
                    <br><br>3. ПРАВА И ОБЯЗАННОСТИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    <br><br>3.1. Субъект персональных данных имеет право:

                    <br>получить сведения, касающиеся обработки своих персональных данных;
                    <br>потребовать исправления неверных, неточных, устаревших персональных данных или уничтожения персональных данных в случае их неправомерной обработки;
                    <br>отозвать согласие на обработку персональных данных;
                    <br>обжаловать в суде любые неправомерные действия или бездействие Компании при обработке и защите его персональных данных.
                    <br><br>3.2. Порядок направления субъектом персональных данных запроса на предоставление сведений об обработке персональных данных определен требованиями Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных». В частности, в соответствии с указанными требованиями запрос, направляемый в Компанию, должен содержать:

                    <br>серию, номер документа, удостоверяющего личность субъекта персональных данных, сведения о дате выдачи указанного документа и выдавшем его органе;
                    <br>сведения, подтверждающие участие субъекта персональных данных в отношениях с Компанией (номер договора, дата заключения договора, условное словесное обозначение и/или иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных в Компании;
                    <br>подпись субъекта персональных данных.
                    <br><br>3.3. Субъект персональных данных несет ответственность за предоставление достоверных сведений, а также за своевременное обновление предоставленных данных в случае каких-либо изменений.

                    <br><br>3.4. Компания предпринимает разумные меры для поддержания точности и актуальности имеющихся персональных данных, а также удаления персональных данных в случаях, если они являются устаревшими, недостоверными или излишними, либо если достигнуты цели их обработки.

                    <br><br>4. КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    <br><br>4.1. Доступ к персональным данным ограничивается в соответствии с требованиями законодательства Российской Федерации и внутренними нормативными документами Компании по вопросам обработки и защиты персональных данных.

                    <br><br>4.2. Работники Компании, получившие доступ к персональным данным, принимают обязательства по обеспечению конфиденциальности обрабатываемых персональных данных.

                    <br><br>4.3. Доступ к персональным данным, обрабатываемым Компанией, на основании и во исполнение нормативных правовых актов, предоставляется органам государственной власти по запросу.

                    <br><br>4.4. Персональные данные субъектов персональных данных могут быть предоставлены третьим лицам с согласия субъекта персональных данных.

                    <br><br>4.5. Обязательным условием поручения обработки персональных данных субъектов и/или предоставления персональных данных субъектов третьим лицам является подписание между Компанией и третьими лицами договоров (соглашений), устанавливающих обязанности по соблюдению режима конфиденциальности, обеспечения безопасности персональных данных субъектов.

                    <br><br>5. СБОР ПЕРСОНАЛЬНЫХ ДАННЫХ С ИСПОЛЬЗОВАНИЕМ САЙТА
                    <br><br>5.1. При сборе персональных данных субъектов посредством информационно-телекоммуникационной сети Интернет Компания обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных субъектов с использованием баз данных, находящихся на территории Российской Федерации.

                    <br><br>5.2. Получение согласия субъекта на сбор и обработку персональных данных осуществляется в случае предоставления субъектом любых личных данных с использованием электронной формы на сайте Компании.

                    <br><br>5.3. Сайт Компании использует файлы «cookie» и собирает сведения, в том числе с использованием сторонних сервисов «Яндекс.Метрика», «Google Analytics» о пользователях, которые необходимы Компании в целях анализа эффективности и улучшения работы сервисов сайта.

                    <br><br>5.4. При посещении сайтов Компании информирует пользователей о сборе и использовании файлов «cookie».

                    <br><br>6. РЕАЛИЗОВАННЫЕ ТРЕБОВАНИЯ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    <br><br>6.1. С целью обеспечения безопасности персональных данных при их обработке в Компании введена, функционирует и проходит периодический пересмотр (контроль) система защиты персональных данных.

                    <br><br>6.2. В Компании назначены лица, ответственные за организацию обработки и обеспечения безопасности персональных данных.

                    <br><br>6.3. Руководство Компании заинтересовано в обеспечении безопасности персональных данных, обрабатываемых в рамках выполнения основной деятельности Компании, как с точки зрения требований нормативных документов Российской Федерации, так и с точки зрения оценки рисков для бизнеса.
                </div>

            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'full-agreement',
        props: ['type'],
    }
</script>

<style scoped lang="scss">
    .blacking {
        position: fixed;
        z-index: 500;
        top: -100vh;
        left: -100vw;
        bottom: -100vw;
        right: -100vh;
        background: #000;
        opacity: 0.5;
        cursor: default;
    }

    .agreement-container {
        top: 30px;
        height: 95vh;
        left: 50%;
        transform: translateX(-50%);
        width: 95vw;
        position: fixed;
        z-index: 1200;
        background: #fff;
        padding: 40px 20px;
        cursor: default;
        color: #000;

        .close {
            position: absolute;
            font-size: 20px;
            top: 10px;
            right: 15px;
            cursor: pointer;
        }

        .agreement-text {
            font-size: 14px;
            overflow: auto;
            max-height: 100%;
            margin: auto;

            .agreement-header {
                font-size: 16px;
                font-weight: 600;
                text-align: center;
                margin-bottom: 20px;
                text-transform: uppercase;
            }

            .agreement-part {
                margin-bottom: 10px;
            }

        }
    }
 </style>
