import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("./pages/main/index.vue"),
    },
    {
      path: "/4pro",
      component: () => import("./pages/model/index.vue"),
      props: {
        model: "tiggo_4_pro",
        compl: "TIGGO 4 PRO",
      },
    },
    {
      path: "/7promax",
      component: () => import("./pages/model/index.vue"),
      props: {
        model: "tiggo_7_pro_max",
        compl: "TIGGO 7 PRO MAX",
      },
    },
    {
      path: "/8promax",
      component: () => import("./pages/model/index.vue"),
      props: {
        model: "tiggo_8_pro_max",
        compl: "TIGGO 8 PRO MAX",
      },
    },
    {
      path: "/arrizo",
      component: () => import("./pages/model/index.vue"),
      props: {
        model: "arrizo",
        compl: "Arrizo 8",
      },
    },
  ],
});

export default router;
