<template>
  <div :class="[device_platform]" class="get-container">
    <div class="blacking" @click="$emit('close')"></div>
    <div class="input-container">
      <div class="closer" @click="$emit('close')">×</div>
      <div class="input-container__title">
        <div 
          :class="{ ['line-through']: get_title['title2-through'] }"
          v-html="get_title.title1"
        >
        </div>
        <div 
          :class="{ ['line-through']: get_title['line-through'] }"
          v-html="get_title.title2"
        >
        </div>
      </div>
      <callback-input
        v-on="$listeners"
        :data="{ type: 'sale', form: 'contacts' }"
        form_class="full-get-form"
        :focusOnMount="true"
        >Отправить</callback-input
      >
    </div>
  </div>
</template>

<script>
import Mask from "../common/mask";
import Keyup from "../common/keyup";
import CallbackInput from "./callback-form/callback-input";
import Mixin from "../common/mixin";

export default {
  name: "full-get",
  components: { CallbackInput },
  mixins: [Mixin],
  directives: { Mask, Keyup },
  props: ["data", "get_title"],
  data() {
    return {
      offices: this.CONSTANTS.offices,
    };
  },
  methods: {
    getAgreement() {
      this.$emit("getAgreement");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";
.line-through {
  text-decoration: line-through;
  text-align: center;
}

.input-container {
  padding: 44px;

  &__title {
    font-size: 30px;
  }
}

.blacking {
  position: fixed;
  top: -100vh;
  left: -100vw;
  bottom: -100vw;
  right: -100vh;
  background: #000;
  opacity: 0.5;
  z-index: 20;
}

.input-container {
  position: fixed;
  top: 18vh;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 21;

  @media (max-width: 767px) {
    width: 90vw;
  }
}

.closer {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.offices-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
