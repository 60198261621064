<template>
  <div id="app">
    <block-header @call-order="fullGetShow" />
    <router-view
      @callBack="callThere"
      @call-order="fullGetShow"
      @open-wheel="openWheel"
    ></router-view>
    <block-map
      @phoneError="showPhoneError"
      @callBack="callThere"
    />
    <block-footer />
    <full-get
      v-if="show.get"
      @phoneError="showPhoneError"
      @close="fullGetClose"
      @callBack="callThere"
      :get_title="get_title"
    />
    <full-agreement v-if="show.agreement" />
    <full-thanks v-if="show.thanks" />
    <phone-error
      @close="closePhoneError"
      v-if="show.phoneError"
    />
    <wheel-of-fortune 
      v-if="show.wheel" 
      @close="closeWheel"
      @call-order="changeModal"
      @callBack="callThere"
    />
  </div>
</template>

<script>
import BlockHeader from "@/components/block-header";
import BlockSubpromo from "@/components/block-subpromo";
import BlockMap from "@/components/block-map";
import BlockFooter from "@/components/block-footer";
import FullGet from "@/components/full-get";
import FullAgreement from "@/components/full-agreement";
import FullThanks from "@/components/full-thanks";
import PhoneError from "./components/phone-error.vue";
import Send from "./common/send";
import $ from "jquery";
import WheelOfFortune from "./components/wheel-of-fortune.vue";

export default {
  name: "App",
  components: {
    BlockHeader,
    BlockSubpromo,
    BlockMap,
    BlockFooter,
    FullGet,
    FullAgreement,
    FullThanks,
    PhoneError,
    WheelOfFortune
},
  data() {
    return {
      show: {
        agreement: false,
        get: false,
        thanks: false,
        popup: false,
        phoneError: false,
        wheel: false
      },
      get_title: {},
      watchers: this.CONSTANTS.watchers,
      data: {},
    };
  },
  mounted() {
    if (!sessionStorage.getItem("referrer") && !sessionStorage.getItem("url")) {
      sessionStorage.setItem("referrer", document.referrer);
      sessionStorage.setItem("url", window.location.href);
    }

    this.$root.$on("get-agreement", () => {
      this.show.agreement = true;
    });

    this.$root.$on("close-agreement", () => {
      this.show.agreement = false;
    });

    this.$root.$on("say-thanks", () => {
      this.show.thanks = true;
    });

    this.$root.$on("close-thanks", () => {
      this.show.thanks = false;
    });
  },
  computed: {
    block_handlers() {
      return {
        callBack: this.callThere,
        callOrder: this.fullGetShow,
      };
    },
  },
  methods: {
    closeThings() {
      this.show.get = false;
      this.show.thanks = false;
      this.show.agreement = false;
      this.show.popup = false;
      this.show.wheel = false;
      this.data = {};
      document.body.classList.remove("scroll-blocked");
    },
    showPhoneError() {
      this.show.phoneError = true;
      document.body.classList.add("scroll-blocked");
    },
    closePhoneError() {
      this.show.phoneError = false;
      document.body.classList.remove("scroll-blocked");
    },
    fullGetClose() {
      this.show.get = false;
      document.body.classList.remove("scroll-blocked");
    },
    fullGetShow(title, title2) {
      console.log('app')
      this.show.get = true;
      // Отправка в метрику только 1 раз

      if (!localStorage.getItem('openFormGoalTriggered')) {
        ym(83419666, "reachGoal", "open_form");
        localStorage.setItem('openFormGoalTriggered', 'true');
      }

      this.get_title = { ...title };
      document.body.classList.add("scroll-blocked");
    },
    openWheel() {
      this.show.wheel = true;
    },
    closeWheel() {
      this.show.wheel = false;
    },
    changeModal(title) {
      this.show.wheel = false;
      this.fullGetShow(title);
    },
    callThere(event) {
      let cKeeper = this.CONSTANTS.cKeeper,
        manager_phone = this.CONSTANTS.need_manager_phone
          ? $("#phone")
              .text()
              .replace(/\s/g, "\u00A0")
              .replace(/-/g, "\u2011")
              .replace(/\D+/g, "")
          : "",
        req = Send(event, this.data, cKeeper, manager_phone);
      this.show.load = true;

      if (cKeeper === "actioncall") {
        const finishCall = () => {
          this.closeThings();
          this.show.load = false;
          this.show.thanks = true;

          if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
              event: "callkeeper-call_order-ckaction",
              eventCategory: "callkeeper",
              eventAction: "call_order",
              eventLabel: "ckaction",
            });
          }
          console.log("done");
        };

        if (!req) {
          finishCall();
        } else {
          req.finally(finishCall);
        }
      } else {
        this.closeThings();
        this.show.load = false;
        this.show.thanks = true;
      }
    },
  },
};
</script>
