export default {
  MARKERS: [
    {
      id: 1,
      hint_content: "ул. Стародеревенская, д.3",
      coords: {
        x: 30.244344,
        y: 59.986484
      },
    },
  ]
}
