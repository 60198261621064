<template>
  <div class="block-yandex-map" id="map1">
    <div class="y-map-content">
      <img
        class="y-map-content__logo"
        src="../img/logo/logo.png"
        alt=""
      />
      <span class="y-map-content__worktime"
        >Мы работаем {{ worktime.from }} до {{ worktime.to }}</span
      >
      <p class="y-map-content__address">{{address}}</p>
      <div class="y-map-content__phone phone-container">
        <a :href="'tel:+' + phone_raw">{{ phone }}</a>
      </div>
      <callback-input
        @phoneError="$emit('phoneError')"
        class="y-map-content__form"
        @callBack="(data) => this.$emit('callBack', data)"
        :data="{ type: 'sale', form: 'contacts' }"
        :horizontal="false"
      >
        Получить предложение
      </callback-input>
    </div>
    <yandex-map
      ymap-class="ymap"
      :zoom="13"
      :coords="[coords.y, coords.x]"
      :controls="['zoomControl']"
    >
      <ymap-marker
        v-for="marker in markers"
        :key="marker.id"
        :marker-id="marker.id"
        :coords="[coords.y, coords.x]"
        :hint-content="marker.hint_content"
        :icon="markerIcon"
      >
<!--      <ymap-marker-->
<!--        v-for="marker in markers"-->
<!--        :key="marker.id"-->
<!--        :marker-id="marker.id"-->
<!--        :coords="[marker.coords.y, marker.coords.x]"-->
<!--        :hint-content="marker.hint_content"-->
<!--        :icon="markerIcon"-->
<!--      >-->
      </ymap-marker>
    </yandex-map>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import MarkersData from "../common/markers-data";
import CallbackInput from "./callback-form/callback-input";

export default {
  components: {
    yandexMap,
    ymapMarker,
    CallbackInput,
  },
  data() {
    const { phone, phone_raw, offices } = this.CONSTANTS;

    return {
      phone,
      phone_raw,
      address: offices[0].address,
      worktime: offices[0].worktime,
      coords: {
        x: offices[0].coords.x,
        y: offices[0].coords.y,
      },
      markers: MarkersData.MARKERS,
      markerIcon: {
        layout: "default#imageWithContent",
        imageHref: "/img/map.balloon.png",
        imageSize: [48, 48],
        imageOffset: [-15, -40],
        content: "some content",
        contentLayout: `
                <div style="color: red; font-weight: bold;">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48" enable-background="new 0 0 48 48" xml:space="preserve">
                        <image id="image0" width="48" height="48" x="0" y="0" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABHVBMVEX////eJyfeJyfeJyfe JyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfe JyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfe JyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfe JyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfe JyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyfeJyf////Nl2FSAAAAXXRSTlMA AAEzgr/Y9NeBMlbZVCPJxyE86exCQfr5P+okxldTsAtAsjF3eoCzvfMMDfUO4N+4tLd8OwLl54yL /f4lsS0wqqkm+/wpnxfu8BlnZsTIeXgDHSBbmJcHzNAJPWnLdcqQAAAAAWJLR0QAiAUdSAAAAAlw SFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB+QCGw0iMDVqlRUAAAF5SURBVEjH3ZXZWsIwEIVJoRQU wSotrSugiOCKUjcEdxQVV9zJ+7+G8OGkQCdJvdRzl5nzt1/TyUkg8K9EfqQEQ2pY0yJqdARKRAAo ozHKFB5TZEA8QQc0rouBiUk6pKQhAswU9ciy+YA+RRElp3mAkqCoZhQOMEs5msMBJcYDIjgQdB3z djqdybrrBRQIsf5i72/lWGEJBVT2fBiIPFSWUYB9gg2AyUYEBQrQLgKwApUCCqx6gDWoaCiwDu0M AAZUNlBgE9pZAEpQ2UKBKNvFXM+/LdnWsvuj8maxaJTcdRkFnB3eaOw6+PDt8YB9zrTGD3B/5ZB3 gKo4UOWeuBr6isoR/0wfY8CJIAROz7z+8wtRzNS9QF2YS5dXw/7GtTj5boaBW0lUOs1B/50jC+Oa 1e+3atL0Jvf9wIM87omjuv7mow+APLGdajz7uFA6agHQ8nMDdfXS878Sv8Dbe9f/8ekbIHqycy3o xD9AvqyUQX4DkHabSIE/q28GF/Jrf0ZEyQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wMi0yN1Qx MzozNDo0OCswMzowMEQxrAEAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDItMjdUMTM6MzQ6NDgr MDM6MDA1bBS9AAAAAElFTkSuQmCC"/>
                    </svg>
                </div>`,
      },
    };
  },
  mounted() {
    console.log(MarkersData)
    // console.log(this.markers)
  }
};
</script>

<style lang="scss">
@import '../styles/constants.scss';

.block-yandex-map {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.y-map-content {
  position: absolute;
  left: 8%;
  top: 6%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgb(65, 65, 65);
  color: #fff;
  padding: 40px 20px;
  min-width: 430px;
  min-height: 400px;
  z-index: 5;
  &__logo {
    width: 300px;
    margin-bottom: 8px;
  }
  &__worktime {
    font-size: 16px;
  }
  &__address{
    font-size: 14px;
    max-width: 300px;
  }
  &__phone {
    font-weight: bold;

    a {
      color: #fff;
    }
  }
  &__form.callback-form {
    width: 400px;
    .agreement .agreement-confirm {
      color: #fff;
      & a {
        color: #fff;
      }
    }
    .input-block {
      width: 100%;
      & input,
      button {
        width: 100%;
      }
      & input {
        color: #fff;
        border: 3px solid $primary;
      }
    }
  }
  @media (max-width: 767px) {
    position: static;
    width: 100%;
    min-width: auto;
    &__form.callback-form {
      width: 100%;
    }
  }
}

.ymap {
  width: 100%;
  height: 480px;

  background: url(../img/map.jpg) 50% 0px no-repeat;

  @media (max-width: 767px) {
    background: url(../img/map.jpg) -1050px 0px no-repeat;
  }
}
</style>
