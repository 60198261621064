<template>
  <div
    class="block-header-container"
    :class="[
      device_platform,
      is_scrolled ? 'block-header-container--scrolled' : '',
    ]"
    id="header"
  >
    <div class="content-container">
      <div class="logo-name-container">
        <div 
          class="logo logo--rolf"
          @click.prevent="$emit('call-order', { title1: 'Получить предложение' })"
        >
          <img
            class="dealer-logo"
            src="../img/logo/logo.png"
            alt="logo_rolf"
          />
          <span>Официальный дилер CHERY в Санкт-Петербурге</span>
        </div>

        <div class="address-worktime-container">
          <a href="#map1">Схема проезда</a>
        </div>
      </div>

      <div class="btn-common">
        <a
          class="btn-red"
          @click.prevent="$emit('call-order', { title1: 'Заказать звонок' })"
        >
          Заказать звонок
        </a>
      </div>

      <div class="phone-container">
        <a :href="'tel:+' + phone_raw">{{ phone }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Mixin from "../common/mixin";

export default {
  name: "block-header",
  mixins: [Mixin],
  data() {
    const { phone, phone_raw, offices } = this.CONSTANTS;

    return {
      phone,
      phone_raw,
      offices,
      is_scrolled: 0,
    };
  },
  mounted() {
    $(window).on("scroll", () => {
      this.is_scrolled = window.scrollY;
    });
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/constants.scss';


.block-header-container {
  background: black;
  color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;

  &--scrolled {
    border-bottom: 1px solid #686d71;
  }
}

.content-container {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto;
  max-width: 1340px;
  padding: 15px;
  text-align: center;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.address-worktime-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 12px;
  font-weight: 500;

  @media (max-width: 767px) {
    display: none;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
  }
}

.phone-container {
  margin-top: 5px;
  @media (max-width: 767px) {
    width: 100%;
    text-align: center;
    order: 3;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    padding: 0 10px;
  }

  a {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 991px) and (min-width: 768px) {
      white-space: nowrap;
    }
  }
}

.btn-common {
  @media (max-width: 767px) {
    display: none;
    width: 100%;
    margin-top: 10px;
    order: 2;
  }

  .btn-red {
    height: 46px;
    width: 230px;

    @media (max-width: 767px) {
      width: 100%;
    }

    @media (max-width: 1199px) and (min-width: 768px) {
      height: 46px;
      width: 170px;
    }
  }
}

.logo--rolf {
  text-align: center;
  max-width: 400px;

  span {
    display: block;
    font-size: 13px;
  }
}

.dealer-logo {
  margin-bottom: 2px;
}

.logo--autodealer {
  position: relative;
  & a {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.logo-name-container {
  @media (max-width: 767px) {
    order: 1;
  }
}

.logo-award {
  max-width: 150px;
}

.btn-common {
  margin-left: auto;
  margin-right: 30px;
}

@media (max-width: 1520px) {
  .block-header-container {
    .content-container {
      padding: 6px 15px;

      .logo--rolf {

        span {
          font-size: 11px;
        }
      }

      .btn-red {
        height: 34px;
        width: 220px;
        font-size: 14px;
      }

      .phone-container {
        margin-top: 0;

        a {
          font-size: 16px;
        }
      }

      .logo-name-container > img {
        max-width: 80px;
      }
    }
  }
}

@media (max-width: 767px) {
  .content-container {
    justify-content: center;
  }

  .logo-name-container {
    max-width: 80%;
  }

  .block-header-container {
    // position: relative;

    &--scrolled {
      border: 0;
    }
  }
}

@media (max-width: 420px) {
  .block-header-container {
    .content-container {
      .logo--rolf span {
        font-size: 8px;
      }
    }
  }
}
</style>
