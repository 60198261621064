import CarsInfo from "./cars-info";
const WEEK = 604800000;
const DAY = 86400000;

const MONTH_NAMES = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export default {
  getNumericalName(number, variants) {
    const numerals = {
      days: ["день", "дня", "дней"],
      hours: ["час", "часа", "часов"],
      minutes: ["минута", "минуты", "минут"],
      seconds: ["секунда", "секунды", "секунд"],
      rubles: ["рубль", "рубля", "рублей"],
      bug: ["баг", "бага", "багов"],
    };
    if (!Array.isArray(variants)) {
      variants = numerals[variants] || numerals.bug;
    }
    let temp = number;
    while (number >= 10) {
      number = number % 10;
    }
    while (temp >= 100) {
      temp = temp % 10;
    }
    if (number > 0 && number < 5) {
      if (temp > 10 && temp < 15) {
        return variants[2];
      }
      if (number == 1) return variants[0];
      return variants[1];
    }
    return variants[2];
  },
  getDateString() {
    return (
      this.getNextDate().getDate() +
      " " +
      this.matchMonth(this.getNextDate().getMonth())
    );
  },
  getNextDate() {
    const WEEK = 604800000;
    const DAY = 86400000;
    let today = new Date();
    let next = new Date(2020, 10, 27, 19, 0, 0),
      next2 = new Date(2020, 10, 30, 22, 0, 0);

    if (today > next && today < next2) {
      next = next2;
    } else {
      next = new Date(2020, 10, 27, 23, 0, 0);

      while (today.valueOf() - next.valueOf() > 0) {
        next = new Date(next.valueOf() + 3 * DAY);
      }
    }
    return next;
  },
  matchMonth(month) {
    return MONTH_NAMES[month];
  },
  getCarAdvantage(name) {
    return CarsInfo.CARS.find(
      (car) => car.name === name
    ).advantage.toLocaleString();
  },
};
