<template>
  <form
    class="callback-form CKiForm"
    :class="[device_platform, { horizontal: horizontal }, form_class]"
    @submit.prevent="submitThis"
  >
    <div class="input-block">
      <input
        :class="{ ['phone-error']: phone_error }"
        name="”tel”"
        type="tel"
        placeholder="Ваш телефон"
        @keydown="numberType"
        v-model="phone"
        ref="phone"
        v-mask
        :pattern="'.*[0-9]{1}.*[0-9]{3}.*[0-9]{3}.*[0-9]{4}'"
        required
      />

      <button
        type="submit"
        :disabled="!acceptance"
        class="btn-red CKFormTrigger"
        :class="[{ disabled: !acceptance }]"
        ref="submitter"
      >
        <slot></slot>
      </button>
    </div>
    <div class="agreement" v-if="CONSTANTS.need_agreement">
      <label class="agreement-confirm">
        <input type="checkbox" v-model="acceptance" />
        <span
          >Нажимая на кнопку "Отправить", я даю
          <a 
            rel="noopenet" 
            href="https://cherydealer.ru/"
            target="_blank"
          >
            согласие
          </a>
          на обработку персональных данных
        </span>
      </label>
    </div>
  </form>
</template>

<script>
import Mixin from "@/common/mixin";
import Mask from "@/common/mask";

export default {
  name: "callback-input",
  directives: { Mask },
  mixins: [Mixin],
  props: ["horizontal", "data", "form_class", "focusOnMount"],
  data() {
    return {
      acceptance: true,
      phone: "",
      phone_error: false,
      index: 1,
			phoneError: false,
    };
  },
  watch: {
    phone: function () {
      const rowPhone = this.phone
        .replace(/\s/g, "\u00A0")
        .replace(/-/g, "\u2011")
        .replace(/\D+/g, "");

      this.isLengthCorrect = rowPhone.length <= 11;
      if (!this.isLengthCorrect) {
        this.$refs.phone.setCustomValidity(
          "Номер должен быть не длиннее 11 символов"
        );
      } else {
        this.$refs.phone.setCustomValidity("");
      }
    },
  },
  computed: {
    inputValidate() {
			let regexp = /\d/g;
			let res = this.phone.match(regexp);
			if (res && res.length === 11) {
					return true;
			} else {
					return false;
			}
		},
		phoneValidate() {
			let regexp = /\d/g;
			let res = this.phone.match(regexp);
			if (res && res.length === 11 && (res[1] == 9 || res[1] == 4)) {
					return true;
			} else {
					return false;
			}
		}
  },
  mounted() {
    if (this.focusOnMount) {
      this.$refs.phone.focus();
    }
  },
  methods: {
    numberType(e) {
			if(this.index === 1 && (e.key === '8' || e.key === '7')) {
				e.preventDefault();
			}
			this.index++;
		},
		clean() {
			this.phone = '';
			this.index = 1;
		},
    submitThis(event) {

			if(!this.inputValidate) {
				return;
			}

			if(!this.phoneValidate) {
				this.$emit('phoneError');
				this.clean();
				return;
			}

      if (this.$refs.phone.validity.valueMissing) {
        this.$refs.phone.setCustomValidity("Введите номер телефона");
      }
      if (this.$refs.phone.validity.minLength) {
        this.$refs.phone.setCustomValidity("Слишком длинный номер");
      }
      if (
        !this.$refs.phone.validity.valueMissing &&
        this.$refs.phone.validity.patternMismatch
      ) {
        this.$refs.phone.setCustomValidity("Введите корректный номер телефона");
      }
      if (
        !this.$refs.phone.validity.valueMissing &&
        !this.$refs.phone.validity.patternMismatch
      ) {
        this.$refs.phone.setCustomValidity("");
      }
      if (this.acceptance && this.$el.reportValidity()) {
        if (typeof ckForms !== "undefined") {
          ckForms.send(this.$el);
        }

        this.$emit("callBack", {
          phone: this.phone,
          data: this.data,
        });

        this.$root.$emit("say-thanks");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/constants.scss";

input:invalid {
  border: 3px solid $primary;
}

.phone-error {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #eba5a3;
  border: 3px solid $primary !important;
}

.callback-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;

  &.horizontal {
    .input-block {
      flex-direction: row;
    }
  }

  &:not(.horizontal) {
    .input-block {
      justify-content: space-between;
      flex-direction: column;

      input {
        margin-bottom: 10px;
      }

      :hover::-webkit-input-placeholder {
        /* Edge */
        color: #fff;
      }

      :hover:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }

      :hover::placeholder {
        color: #fff;
      }
    }
  }

  .input-block {
    width: 610px;
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
    }

    input {
      border: 1px solid #fff;

      @media (max-width: 767px) {
        margin-bottom: 10px;

        color: #000;

        border: 1px solid #000;

        &:focus {
          color: #000;
        }
      }
    }

    input,
    button {
      height: 46px;
      width: 48%;

      color: #fff;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .disabled {
      background-color: #ccc;
      color: #000;

      border: none;
    }
  }

  .agreement {
    display: flex;

    .agreement-confirm {
      display: flex;
      align-items: center;
      margin-top: 10px;

      font-size: 12px;
      color: #fff;

      @media (max-width: 767px) {
        text-align: left;
        color: #000;
      }

      a {
        color: #fff;

        @media (max-width: 767px) {
          color: #000;
        }
      }
    }
  }
}

.full-get-form {
  .input-block {
    display: block;
    width: 100%;

    input::-webkit-input-placeholder {
      /* Edge */
      color: #000 !important;
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000 !important;
    }

    input::placeholder {
      color: #000 !important;
    }

    input {
      margin-bottom: 10px;

      border: 1px solid #a1a1a1;

      &:focus {
        color: #000;
      }
    }

    input,
    button {
      height: 46px;
      width: 100%;

      color: #fff;
    }
  }

  .agreement {
    display: flex;

    .agreement-confirm {
      display: flex;
      align-items: center;
      margin-top: 10px;

      font-size: 12px;
      color: #000;

      a {
        text-decoration: underline dotted black;
        color: #000;
      }
    }
  }
}


.counteroffer {
  .input-block {
    input {
      background: #fff;
      color: #000;
      border: 1px solid #000;
    }
  }
}

@media (max-width: 767px) {
  .block-counteroffer {
    .callback-form {
      .agreement {
        .agreement-confirm {
          color: #FFFFFF;
          
          a {
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .wheel {
    .full-get-form {
      .input-block {
        input,
        button {
          height: 38px;
        }
      }

      .agreement {
        .agreement-confirm {
          font-size: 8px;
        }
      }
    }
  }
}
</style>
