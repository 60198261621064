import Vue from "vue";
import router from "./router";
import App from "./App.vue";
import Constants from "./common/constants";
import "./styles/index.scss";
import "swiper/swiper-bundle.min.css";

Vue.config.productionTip = false;
Vue.prototype.CONSTANTS = Constants;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
