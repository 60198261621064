<template>
    <div class="container">
		<div class="block2">
			<div class="timer_container">
				<div class="timer_title">
					Торопитесь! До окончания акции осталось:
				</div>
				<div class="timer">
					<block-clock-megatimer />
				</div>
			</div>
			<div class="special">
				<div class="special_text">
					<div class="special_title">
						Звоните <a :href="'tel:+' + phone_raw">{{ phone }}</a>
						и успейте<br> получить СПЕЦ. УСЛОВИЯ на CHERY!
					</div>
					<div class="special_comment">
						Cейчас предложение <br>
						просматривают <span>{{ watchers }} человек</span>
					</div>
				</div>
				<div class="btn">
					<button
						class="btn-red"
						@click.prevent="$emit('call-order', { title1: 'Заказать звонок'})"
					>Заказать звонок</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BlockClockMegatimer from "./block-clock-megatimer";

export default {
	name: "block-subpromo",
	components: {
		BlockClockMegatimer
	},
	data() {
		const {
			phone,
			phone_raw
		} = this.CONSTANTS;

		return {
			phone,
			phone_raw,
			watchers: this.CONSTANTS.watchers
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.btn {
	width: 250px;

	@media (max-width: 767px) {
		width: 100%;
	}
}

.container {
    margin: 0px auto;
    max-width: 1340px;
    position: relative;
    padding: 0px 15px;
    text-align: center;
}

.timer_container {
    width: 43%;
    background: white;
	padding: 20px 35px;
	
	@media (max-width: 991px) and (min-width: 768px) {
		width: 100%;
	}

	@media (max-width: 767px) {
		width: 100%;
        padding: 20px 15px;
	}
}

.timer_title {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 10px;
}

.special {
	background: #686d71;
	padding: 30px 35px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	width: 100%;

	&_title {
		color: white;
		font-size: 20px;
		padding-bottom: 20px;

		a {
			color: #bfc2c4;
		}

		br {
			@media (max-width: 1199px) and (min-width: 768px) {
				display: none;
			}

			@media (max-width: 991px) and (min-width: 768px) {
				display: block;
			}

			@media (max-width: 320px) {
				display: none;
			}
		}
	}

	&_comment {
		color: white;
		font-size: 18px;

		span {
			color: #bfc2c4;
		}
	}

	@media (max-width: 767px) {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;

		&_title {
			font-size: 16px;
		}

		&_comment {
			font-size: 14px;
		}

		&_text {
			margin-bottom: 20px;
		}
	}
}

.block2 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0 0 25px 5px rgba(0,0,0,.17);
	margin-top: 40px;

	@media (max-width: 320px) {
		margin-top: 40px;
	}

	@media (max-width: 767px) {
		flex-wrap: wrap;
	}
	
	@media (max-width: 991px) and (min-width: 768px) {
		flex-wrap: wrap;
	}
}

.block2_bg {
    background: url(../img/banner2_bg.jpg) top no-repeat;
	padding: 75px 0;

	@media (max-width: 320px) {
		background-position: right 120px;
        padding: 40px 0;
	}

	@media (max-width: 380px) {
        background-position: right 165px;
	}
	
	@media (max-width: 767px) {
		background: url(../img/banner2_bg.jpg) top no-repeat;
        padding: 75px 0;
        background-size: 200%;
        background-position: right 175px;
	}
}

.block2_title {
    font-size: 55px;
    color: $primary;
    text-transform: uppercase;
	font-weight: 500;

	@media (max-width: 320px) {
		font-size: 28px;
	}

	@media (max-width: 360px) {
		font-size: 30px;
	}

	@media (max-width: 380px) {
		font-size: 32px;
        font-weight: 600;
	}
	
	@media (max-width: 767px) {
		font-size: 36px;
        font-weight: 600;
	}
}

.block2_comment {
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
	margin-top: 60px;

	@media (max-width: 320px) {
		margin-top: 270px;
	}

	@media (max-width: 380px) {
		margin-top: 315px;
	}
	
	@media (max-width: 767px) {
		font-weight: 600;
        margin-top: 350px;
        color: black;
	}
}
</style>